import React, { useEffect } from "react";
import posthog from "posthog-js";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

posthog.init("phc_IOPsa9imGiFcqlTx8T9b7z7j50Wjx6LiwMwCGBCnHEa", {
    api_host: "https://app.posthog.com",
    autocapture: true,
    persistence: "localStorage",
    secure_cookie: true,
});

function LandingPage() {
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    useEffect(() => {
        setTimeout(function () {
            posthog.capture("Automatic Redirect");
            setTimeout(function () {
                window.location.href = "https://andisearch.com/";
            }, 500);
        }, 29500);
    }, []);

    return (
        <>
            <div className="wrapper">
                <LandingPageHeader />
                <div
                    className="section section-about-us"
                    style={{ paddingTop: "24px" }}
                >
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h1
                                    className="title"
                                    style={{ fontSize: "2.2em" }}
                                >
                                    LazyWeb is now{" "}
                                    <a
                                        style={{ fontWeight: "bold" }}
                                        href="https://andisearch.com/"
                                    >
                                        Andi
                                    </a>
                                </h1>

                                {/* <h4>What happened to LazyWeb?</h4> */}
                                <h5
                                    className="description"
                                    style={{ color: "black" }}
                                >
                                    <a
                                        style={{ fontWeight: "bold" }}
                                        href="https://andisearch.com/"
                                    >
                                        Andi
                                    </a>{" "}
                                    is a new type of conversational search
                                    engine. It's ad-free and anonymous, and
                                    returns only the best and most relevant
                                    results. We wanted to let you know about the
                                    change so you can update your bookmarks. We
                                    changed the name to{" "}
                                    <a
                                        style={{ fontWeight: "bold" }}
                                        href="https://andisearch.com/"
                                    >
                                        Andi
                                    </a>{" "}
                                    after a ton of feedback from our community.
                                    That was already the name of our little bot
                                    😎
                                </h5>

                                <Button
                                    color="primary"
                                    size="lg"
                                    style={{
                                        fontSize: "1.2em",
                                        borderRadius: "32px",
                                        //border: "solid",
                                        marginBottom: "1.2em",
                                    }}
                                    as="a"
                                    href="https://andisearch.com/"
                                >
                                    <strong>
                                        Go to Andi search{" "}
                                        <span role="img" aria-label="yay">
                                            🔍
                                        </span>
                                    </strong>
                                </Button>

                                <h5 style={{ color: "#3B75FA" }}>
                                    We'll automatically redirect you after 30
                                    seconds.
                                </h5>

                                <h3 className="title">
                                    LazyWeb Inc - Company Information
                                </h3>
                                <p>
                                    LazyWeb Inc is a new startup based in Miami,
                                    and we're proud to be building Andi search.
                                    For corporate information, please email us
                                    at info [at] lazyweb.ai
                                </p>
                                <h5>
                                    Thank you for being part of our community.{" "}
                                </h5>
                            </Col>
                        </Row>
                        <div className="separator separator-primary"></div>
                    </Container>
                </div>

                <DefaultFooter />
            </div>
        </>
    );
}

export default LandingPage;
