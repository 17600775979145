import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function LandingPageHeader() {
    let pageHeader = React.createRef();

    React.useEffect(() => {
        if (window.innerWidth > 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });
    return (
        <>
            <div className="page-header page-header-small">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage:
                            "url(" + require("assets/img/1500x500.jpeg") + ")",
                        backgroundColor: "#7AA4FC",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                    }}
                    ref={pageHeader}
                ></div>
                <div className="content-center">
                    <Container>
                        <Button
                            color="primary"
                            size="lg"
                            style={{
                                fontSize: "1.5em",
                                borderRadius: "32px",
                                border: "solid",
                                marginTop: "300px",
                            }}
                            as="a"
                            href="https://andisearch.com/"
                        >
                            <strong>
                                Search now with Andi{" "}
                                <span role="img" aria-label="yay">
                                    🤩 🤖 🔍
                                </span>
                            </strong>
                        </Button>
                        {/* <img
                            alt="..."
                            style={{
                                align: "left",
                                marginTop: "320px",
                                height: "200px",
                                width: "auto",
                                borderRadius: "32px",
                                
                            }}
                            className="img-fluid img-raised"
                            //width="70%"
                            src={require("assets/img/andi-a-logo.jpg")}
                        ></img> */}
                        <div className="text-center"></div>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default LandingPageHeader;
